import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Featured Systems`}</h2>
    <ProjectCard title="Marketing Firm" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Marketing is partially algorithmic, and the skills transfer to many topics.
  A centralized marketing strategy for many value-propositions allows powerful social movements.
    </ProjectCard>
    <ProjectCard title="Accounting Firm" link="#" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  By centralizing an accounting skillset, we can deliver premium 
  accounting services to all projects we engage in, right out of the gate.
    </ProjectCard>
    <ProjectCard title="Software Development Firm" link="#" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Most systems need to automate parts of it, and we're pros at this.
  Our dedicated team of engineers can solve problems encountered by many different teams.
    </ProjectCard>
    <ProjectCard title="Incubator / Venture Capital" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  By centralizing the concern of funding early-stage projects,
  we can optimize the timing of investments across domains.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      