import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LogoImage from '../components/logo-image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <LogoImage mdxType="LogoImage" />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Swarm Intelligence (SI)`}</strong>{` is the collective behavior of decentralized, self-organized systems, natural or artificial. [`}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/Swarm_intelligence"
        }}>{`1`}</a>{`]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`-- Wikipedia`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      